/* HELPERS */

// convert input to "lat,lng"
const LLToString = (input) => (input.lat && [input.lat, input.lng].join(',')) || input

// convert input to { lat: '', lng: '' }
const LLToObject = (input) => ({
  lat: parseFloat(input.lat || input.replace(/\s/g, '').split(',')[0], 10),
  lng: parseFloat(input.lng || input.replace(/\s/g, '').split(',')[1], 10)
})

const googleMappingKeys = {
  // keys are on google response types
  // modifier return array with our keys & value
  locality: (item) => ['city', item.long_name],
  route: (item) => ['street', item.long_name],
  street_number: (item) => ['street_number', item.long_name],
  postal_code: (item) => ['zipcode', item.long_name],
  administrative_area_level_1: (item) => ['region', item.long_name],
  administrative_area_level_2: (item) => ['state', item.long_name],
  place_id: (item) => ['place_id', item.place_id],
  country: [(item) => ['country', item.long_name], (item) => ['country_code', item.short_name]]
}

const googleMappingReducer = (acc, item) => {
  if (item.geometry)
    acc.latlng = [item.geometry.location.lat(), item.geometry.location.lng()].join(',')
  if (item.place_id) acc.place_id = item.place_id
  if (item.formatted_address) acc.address = item.formatted_address
  if (!acc.types && item.types) acc.types = item.types

  // if one of object type match our mapping
  // then add corresponding key:value to returned accessor
  const field = googleMappingKeys[item.types[0]]
  const modifiers = typeof field == 'function' ? [field] : field || []
  modifiers.forEach((fn) => {
    const [key] = fn(item)
    const [, value] = fn(item)
    if (value) acc[key] = value
  })

  return acc
}

const googleDataParser = (results) => {
  try {
    return [results[0], ...results[0].address_components]
  } catch {
    return []
  }
}

// check if marker is within distance (kilometers) of a center point
const inRadius = (center, marker, km = 10) => {
  center = LLToObject(center)
  marker = LLToObject(marker)
  const ky = 40000 / 360
  const kx = Math.cos((Math.PI * center.lat) / 180) * ky
  const dx = Math.abs(center.lng - marker.lng) * kx
  const dy = Math.abs(center.lat - marker.lat) * ky
  return Math.sqrt(dx * dx + dy * dy) <= km
}

// convert input to geocoded object
const geocode = async (params) => {
  const geocoder = new window.google.maps.Geocoder()
  const OK = window.google.maps.GeocoderStatus.OK

  return new Promise((resolve, reject) => {
    geocoder.geocode(params, (results, status) => {
      if (status !== OK) return reject(status)
      const data = googleDataParser(results).reduce(googleMappingReducer, {})
      if (!Object.keys(data).length) return reject('no data found')
      resolve(data)
    })
  })
}

// get timezone from lat,long
const timezone = async (latlng) => {
  const endpoint = `https://maps.googleapis.com/maps/api/timezone/json?location=${latlng}&timestamp=${Math.round(
    Date.now() / 1000
  )}&key=${process.env.GMAP_KEY}`
  return fetch(endpoint)
    .then((response) => response.json())
    .then((data) => data.timeZoneId)
}

export default {
  LLToString,
  LLToObject,
  inRadius,
  geocode,
  timezone
}
