import React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import geo from '../../lib/geo'
import {withGlobal} from '../shared/app'

let throttle = false

const GmapInput = (props) => {
  const {
    t,
    value,
    placeholder,
    className = 'form-control',
    errkey,
    erralias,
    onChange,
    shortAddress,
    manualOption = {active: false, onSelect: () => {}}
  } = props

  const handleChange = (address) => {
    onChange({address, geocode: {}})
  }

  const handleSelect = (address, placeId, suggestion) => {
    if (throttle) clearTimeout(throttle)

    if (suggestion && suggestion.manual) {
      manualOption.onSelect()
      return
    }

    // append latlng with throttle
    throttle =
      (address || placeId) &&
      setTimeout(async () => {
        try {
          const address_short = (address || '').split(',')[0]
          const location = await geo.geocode(
            placeId ? {placeId: placeId} : {address},
            process.env.GMAP_KEY
          )

          const result = {
            address: shortAddress ? address_short : address,
            latlng: {...geo.LLToObject(location.latlng), string: location.latlng},
            geocode: {...location},
            tz: await geo.timezone(location.latlng)
          }

          onChange && onChange(result)
        } catch (e) {
          console.log(e)
        }
      }, 500)
  }

  return (
    <div style={{position: 'relative'}}>
      <PlacesAutocomplete value={value} onChange={handleChange} onSelect={handleSelect}>
        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
          <form autoComplete='off'>
            <input
              errkey={errkey || ''}
              erralias={erralias || ''}
              className={`${className}`}
              autoComplete='chrome-off'
              {...getInputProps({placeholder})}
            />
            {!!(loading || suggestions.length) && (
              <div className='autocomplete-dropdown-container'>
                {loading && <div className='suggestion-item'>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active ? 'suggestion-item active' : 'suggestion-item'

                  if (manualOption.active && index > 3) return null

                  return (
                    <div className={className} {...getSuggestionItemProps(suggestion)} key={index}>
                      {/*<i className="fa fa-map-marker-alt mr-1" />*/}
                      <strong>{suggestion.formattedSuggestion.mainText}</strong>{' '}
                      <small>{suggestion.formattedSuggestion.secondaryText}</small>
                    </div>
                  )
                })}
                {manualOption.active && (
                  <div
                    className='suggestion-item-manual'
                    {...getSuggestionItemProps({manual: true})}>
                    <img
                      className='mr-2'
                      style={{marginTop: '-4px'}}
                      width='15px'
                      src='https://res.cloudinary.com/inmemori/image/upload/v1627983374/web-assets/picto/edit-black.svg'
                    />
                    <strong>{t('common.manual-address.main')}</strong>{' '}
                    {!!t('common.manual-address.secondary', true) && (
                      <small>{t('common.manual-address.secondary')}</small>
                    )}
                  </div>
                )}
              </div>
            )}
          </form>
        )}
      </PlacesAutocomplete>

      <style jsx>
        {`
          .autocomplete-dropdown-container {
            background-color: #fff;
            border: 1px solid #e0dedc;
            border-radius: 4px;
            position: absolute;
            top: 100%;
            width: 100%;
            z-index: 10000;
          }

          .suggestion-item {
            background: transparent;
            padding: 10px;
            color: #555555;
            cursor: pointer;
            font-size: 12px;
          }

          .suggestion-item.active {
            background: #ededed;
          }

          .suggestion-item-manual {
            background: transparent;
            padding: 10px;
            color: #555555;
            cursor: pointer;
            font-size: 12px;
          }

          .suggestion-item-manual:hover {
            background: #ededed;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(GmapInput)
