import React, {useState, useEffect, useContext} from 'react'
import _ from 'lodash'

const FluxContext = React.createContext({})

// UTILITY FUNCTIONS

// keyToStep(0)
// keyToStep('payment')
const keyToStep = (key, steps) => {
  if (typeof key == 'number') return {...steps[key], index: key}

  if (typeof key == 'string')
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].key == key) {
        return {...steps[i], index: i}
      }
    }
}

// add index to steps, also remove exluded steps
const cleanSteps = (steps, exclude = []) => {
  const clean = []
  for (let i = 0; i < steps.length; i++) {
    steps[i].index = i
    if (!exclude.includes(steps[i].key)) clean.push(steps[i])
  }
  return clean
}

const asArray = (input, {delim = ',', trim = true, uniq = true, compact = true} = {}) => {
  let output = typeof input == 'string' ? input.split(delim) : input || []

  if (trim) output = _.map(output, (v) => (typeof v == 'string' ? v.trim() : v))
  if (compact) output = _.compact(output)
  if (uniq) output = _.uniq(output)

  return output
}

// COMPONENTS

export const FluxBar = ({steps, current, flux}) => (
  <ul className='tabs d-none d-md-flex flex-column flex-md-row justify-content-between m-0 p-0 no-select'>
    {steps.map((s, i) => {
      return (
        <li
          key={i}
          className={`d-flex justify-content-center align-items-center ${
            s.key == current.key ? 'active' : ''
          } ${s.index > current.index ? 'text-lighter' : ''}`}>
          <span className='f1 sbold'>
            {i + 1}
            <span className=''>· {flux.t('label', {}, s)}</span>
          </span>
        </li>
      )
    })}
  </ul>
)

export const FluxDebug = () => {
  const [show, setShow] = useState(true)
  const ctx = useContext(FluxContext)
  const {steps, step, setStep} = ctx

  if (!show) return null

  return (
    <div className='text-center py-3'>
      <button
        onClick={() => setStep('prev', true)}
        className={`btn btn-sm btn-outline-light px-2 py-0 `}>
        {`<`}
      </button>
      <button
        onClick={() => setStep('next', true)}
        className={`btn btn-sm btn-outline-light px-2 py-0 `}>
        {`>`}
      </button>
      {steps.map((s, i) => {
        return (
          <button
            key={i}
            onClick={() => setStep(s.key)}
            className={`btn btn-sm btn-outline-light px-2 py-0 ${
              s.key == step.key ? 'active' : ''
            }`}>
            {i + 1}
            <span className=''>· {s.title}</span>
          </button>
        )
      })}
      <button onClick={() => setShow(false)} className={`btn btn-sm btn-outline-light px-2 py-0 `}>
        x
      </button>

      <style jsx>
        {`
          button {
            font-size: 10px;
            border-radius: 0px;
            margin-left: -1px;
          }
        `}
      </style>
    </div>
  )
}

export const Flux = ({flux, children, debug}) => {
  return (
    <FluxContext.Provider value={flux}>
      {debug && <FluxDebug />}
      {false && JSON.stringify(flux.step)}
      {typeof children == 'object' && children}
      {typeof children == 'function' && children(flux)}
    </FluxContext.Provider>
  )
}

// takes an array of steps
// key is mandatory, label is optional, but needed for debug
// [
//     { "key": "card", "label": "your cart" }
//   , { "key": "payment", "label": "enter card details" }
// ]

export const useFlux = ({
  steps: stepsArray,
  excludeSteps = [],
  defaultStep = () => 0,
  hasPrev: hasPrev_,
  hasNext: hasNext_,
  onPrevMiss = () => {},
  onNextMiss = () => {},
  onChange = () => {},
  onCheckpoint = () => {},
  t: t_
}) => {
  const [steps, setSteps_] = useState(cleanSteps(stepsArray, excludeSteps))
  const [step, setStep_] = useState(
    keyToStep((typeof defaultStep == 'string' ? defaultStep : defaultStep()) || 0, stepsArray) || {}
  )
  const [checkpoints, setCheckpoints] = useState(
    steps.reduce((acc, step) => {
      acc[step.key] = false
      return acc
    }, {})
  )
  const setCheckpoint = (key) => {
    if (checkpoints[key]) return false
    setCheckpoints({...checkpoints, [key]: true})
    return true
  }

  const hasPrev = () => (hasPrev_ || (() => step.index != 0))({step, steps})
  const hasNext = () => (hasNext_ || (() => step.index != steps.length - 1))({step, steps})

  const isStep = (keys) => step && asArray(keys).includes(step.key)

  // getSteps('card,payment')
  const getSteps = (keys, inverse = false) =>
    _.filter(steps, (s) =>
      inverse ? !asArray(keys).includes(s.key) : asArray(keys).includes(s.key)
    )
  const setSteps = () => setSteps_(cleanSteps(stepsArray, excludeSteps))

  const setStep = (key, byPassCondition) => {
    let newStep
    if (key == 'prev') {
      if (hasPrev() || byPassCondition) newStep = {...steps[step.index - 1]}
      else onPrevMiss()
    } else if (key == 'next') {
      if (hasNext() || byPassCondition) newStep = {...steps[step.index + 1]}
      else onNextMiss()
    } else newStep = keyToStep(key, stepsArray)

    if (newStep) {
      setStep_(newStep)
      onChange(newStep)
      if (setCheckpoint(newStep.key)) onCheckpoint(newStep)
      if (typeof window !== 'undefined' && typeof window.hj !== 'undefined') {
        console.log(`hotjar state change to ${window.location.pathname}/${newStep.key}`)
        window.hj('stateChange', `${window.location.pathname}/${newStep.key}`)
      }
    }
  }

  // translate middleware, usefull when regenration of i18n
  // fn take the t props, or default to direct proxy for object key
  // t() can accept a 2nd arg, which is the ref object to pull the key from, without it, object is the current step
  const t = (key, subs = {}, ref) => {
    const fn = (t_ && t_({step})) || ((key, subs, ref) => (ref || step)[key])
    return fn(key, subs, ref)
  }

  useEffect(() => {
    // initial checkpoint
    if (setCheckpoint(step.key)) onCheckpoint(step)
  }, [])

  // sync steps (on lang switch for exemple)
  useEffect(() => {
    // only update if steps exists, feeding an empty array make this function loop for whatever reason
    // it does not really makes sense to use this hook without some steps anyway
    if (stepsArray.length) {
      setSteps(stepsArray)
      step && setStep_(keyToStep(step.key, stepsArray))
    }
  }, [stepsArray])

  const flux = {
    steps,
    setSteps,
    getSteps,
    step,
    setStep,
    hasPrev,
    isStep,
    t
  }

  return flux
}
