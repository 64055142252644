import React from 'react'

export const Btn = ({value, icon, className = '', onClick, children, title = '', style = {}}) => (
  <button
    title={title}
    onClick={onClick}
    style={style}
    className={`${className || 'btn btn-primary'}`}>
    {icon && icon.startsWith('http') && (
      <img className='mr-2' style={{marginTop: '-4px'}} width='18px' src={icon} />
    )}
    {icon && icon.startsWith('fa') && <i className={`mr-2 ${icon}`} />}
    {value || children}
  </button>
)

export const BtnSpin = ({
  icon,
  isLoading,
  children,
  className = '',
  pristine,
  disabled,
  style,
  ...props
}) => {
  const [showLoader, setShowLoader] = React.useState(false)
  const Loader = () => <i className='fa fa-circle-notch fa-spin' />

  React.useEffect(() => {
    if (isLoading) setShowLoader(true)

    // Show loader a bits longer to avoid loading flash
    if (!isLoading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false)
      }, 400)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isLoading, showLoader])

  return (
    <button
      {...props}
      className={className}
      disabled={disabled || pristine || isLoading}
      style={style}>
      {icon && !showLoader ? <i className={`${children ? 'mr-2' : ''} ${icon}`}></i> : null}
      {showLoader ? <Loader /> : children}
    </button>
  )
}

export const BtnPrev = ({
  value,
  disabled = false,
  className = '',
  onClick,
  children,
  title = '',
  style = {}
}) => (
  <button
    title={title}
    disabled={disabled}
    onClick={onClick}
    style={style}
    className={`${className || 'btn btn-primary'}`}>
    <i className={`mr-2 fas fa-arrow-left`} />
    {value || children}
  </button>
)

export const BtnNext = ({
  value,
  disabled = false,
  className = '',
  onClick,
  children,
  title = '',
  style = {}
}) => (
  <button
    title={title}
    disabled={disabled}
    onClick={onClick}
    style={style}
    className={`${className || 'btn btn-primary'}`}>
    {value || children}
    <i className={`ml-2 fas fa-arrow-right`} />
  </button>
)
