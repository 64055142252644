export const DebugBlock = ({title = '', className='mt-3', children}) => {
  return (
    <div className={`debug pre-line ${className}`}>
      <div className='title'>[debug] {title}</div>
      <div>{children}</div>

      <style jsx>
        {`
          .debug {
            padding: 10px;
            border-radius: 10px;
            border: 2px dashed #eee;
          }
          .title {
            font-size: 10px;
          }
        `}
      </style>
    </div>
  )
}
